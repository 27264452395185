import React, {Component} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Resources from '../../resources/afiservicios-resources.json'
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const CustomSelectWrapper = styled.div`
  margin-top: 20px;

  .MuiAutocomplete-tag{
    background: var(--four-color);
  }
`; 

class CustomSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMsg: Resources.strings.field_required,
      tags: []
    };
    this.onTagsChange = this.onTagsChange.bind(this);
  }

  render() {
    return (
      <CustomSelectWrapper style={this.props.containerStyle}>
        <FormControl error={this.props.invalid && this.props.submitted}
                     fullWidth >
          <Autocomplete
            multiple
            id={this.props.id}
            options={this.props.options}
            getOptionLabel={(option) => option}
            onChange = {this.onTagsChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={this.props.label}
              />
            )}
          />
          
          <FormHelperText>
            {
              this.props.invalid && this.props.submitted
                ? this.state.errorMsg
                : this.props.hint
            }
          </FormHelperText>
        </FormControl>
      </CustomSelectWrapper>
    );
  }

  onTagsChange = (event, values) => {
    this.setState({
      tags: values
    }, () => {
      console.log(this.state.tags);
    });
    const valid = this.checkValid(values);
    return this.props.onChange(values, valid);
  }

  checkValid(value) {
    let valid = true;
    let msg = null;

    if (this.props.required) {
      if (!(value != null && value.length !== 0)) {
        valid = false;
        msg = Resources.strings.field_required;
      }
    }

    this.setState({
      errorMsg: msg
    });
    return valid;
  }
}

CustomSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  submitted: PropTypes.bool,
  containerStyle: PropTypes.object,
  hint: PropTypes.string,
  inputProps: PropTypes.object
};

CustomSelect.defaultProps = {
  required: false,
  invalid: false,
  submitted: false,
  containerStyle: {},
  inputProps: {},
  hint: ''
};

export default CustomSelect;
