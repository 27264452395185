import React, {Component} from "react";
import styled from "styled-components";
import CustomInput from "./input"
import CustomButton from "./button"
import Resources from "../../resources/afiservicios-resources.json"
import CustomMap from "./map"
import { sendContactForm } from '../services/contact-form'
import CustomSelect from "./select"
import Fade from 'react-reveal/Fade'

const ContactFormWrapper = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: var(${Resources.contact_form.bg_color});
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    padding-bottom: 0;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 0;
  }
`;

const ContactFormTitle = styled.span`
  font-family: var(${Resources.contact_form.title.font});
  font-size: ${Resources.contact_form.title.size};
  color: var(${Resources.contact_form.title.color});
  text-transform: uppercase;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.contact_form.title.size_small};
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.contact_form.title.size_med};
  }
`;

const ContactFormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;

  .contactFormStyle{
      margin-left: 40px;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 0;

    .contactFormStyle{
      margin-left: 0;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }
`;

const ContactButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    justify-content: center;
  }
`;

const ContactMapContainer = styled.div`
  margin: auto 0;
  grid-row: 1;
  grid-column: 1;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 2;
    margin-top: 60px;
    margin-left: -30px;
    margin-right: -30px;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 2;
    margin-top: 60px;
    margin-left: -30px;
    margin-right: -30px;
  }
`;

const ContactMsgContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  
  .success-msg {
    color: var(--primary-second-color);
  }
  
  .error-msg {
    color: var(--error-color);
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    justify-content: center;
  }
`;

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      business: '',
      service: '',
      email: '',
      phone: '',
      msg: '',
      submitted: false,
      errors: {
        name: true,
        service: true,
        email: true,
        phone: true,
        msg: true,
      },
      isLoading: false,
      sendMsg: null,
      sendMsgType: null,
    };
  }

  render() {
    return (
      <ContactFormWrapper id="contact-form-id">
        <Fade big>
        <div className="container" style={{ marginBottom: 0 }}>
          <ContactFormContainer>
            <ContactMapContainer>
              <CustomMap />
            </ContactMapContainer>
            <div className="contactFormStyle">
              <ContactFormTitle>
                {Resources.contact_form.title.label}
              </ContactFormTitle>
              <form noValidate autoComplete="off">
                <CustomInput label={Resources.strings.name + ' *'}
                             name="name"
                             id="name"
                             required={true}
                             submitted={this.state.submitted}
                             invalid={this.state.errors['name']}
                             onChange={(val, v) => this.updateState('name', val, v)}
                             type="text" />
                <CustomInput label={Resources.strings.business}
                             name="business"
                             id="business"
                             required={false}
                             submitted={this.state.submitted}
                             invalid={false}
                             onChange={(val, v) => this.updateState('business', val, v)}
                             type="text" />
                <CustomSelect label={Resources.strings.service_required}
                              required={true}
                              submitted={this.state.submitted}
                              invalid={this.state.errors['service']}
                              onChange={(val, v) => this.updateState('service', val, v)}
                              options={Resources.contact_form.service_options}
                              id="service" />
                <ContactFormContainer>
                  <CustomInput label={Resources.strings.email + ' *'}
                               name="email"
                               id="email"
                               required={true}
                               submitted={this.state.submitted}
                               invalid={this.state.errors['email']}
                               onChange={(val, v) => this.updateState('email', val, v)}
                               type="email" />
                  <CustomInput label={Resources.strings.phone + ' *'}
                               name="phone"
                               id="phone"
                               required={true}
                               submitted={this.state.submitted}
                               invalid={this.state.errors['phone']}
                               onChange={(val, v) => this.updateState('phone', val, v)}
                               minLength={10}
                               maxLength={10}
                               type="number" />
                </ContactFormContainer>
                <CustomInput label={Resources.strings.message + ' *'}
                             name="msg"
                             id="msg"
                             required={true}
                             submitted={this.state.submitted}
                             invalid={this.state.errors['msg']}
                             onChange={(val, v) => this.updateState('msg', val, v)}
                             rowsMax={5}
                             type="textarea" />
                {
                  this.state.sendMsg &&
                  <ContactMsgContainer>
                    <span className={this.state.sendMsgType}>{this.state.sendMsg}</span>
                  </ContactMsgContainer>
                }
                <ContactButtonContainer>
                  <CustomButton label={Resources.contact_form.button.label}
                                view={Resources.contact_form.button.view}
                                indicatorColor={Resources.contact_form.button.indicator_color}
                                fullWidth={false}
                                containerStyle={{ marginTop: '30px' }}
                                onClick={() => this.handleSignup()}
                                isLoading={this.state.isLoading} />
                </ContactButtonContainer>
              </form>
            </div>
          </ContactFormContainer>
        </div>
        </Fade>
      </ContactFormWrapper>
    )
  }

  updateState(prop, value, valid) {
    const state = this.state;
    const errors = state.errors;
    state[prop] = value;
    errors[prop] = !valid;
    state.errors = errors;
    this.setState(state);
  };

  checkCompleted() {
    return !this.state.errors['name']
      && !this.state.errors['service']
      && !this.state.errors['email']
      && !this.state.errors['phone']
      && !this.state.errors['msg'];
  }

  handleSignup() {
    this.setState({
      submitted: true,
      sendMsg: null,
    }, () => {
      if (this.checkCompleted()) {
        this.setState({
          isLoading: true
        }, () => {
          this.makeCall();
        })
      }
    });
  };

  makeCall() {
    const business = this.state.business.length > 0
      ? this.state.business
      : null;

    sendContactForm(
      this.state.name,
      this.state.email,
      this.state.service,
      business,
      this.state.phone,
      this.state.msg
    ).then(res => {
      this.setState({
        isLoading: false,
        sendMsg: res,
        sendMsgType: 'success-msg',
      });
    }).catch(error => {
      this.setState({
        isLoading: false,
        sendMsg: error,
        sendMsgType: 'error-msg',
      });
    });
  }
}

export default ContactForm;
