import React from "react"
import styled from "styled-components"

const Wrapper = styled.section`
  width: 100%;
  background-color: #1a1a1a;
  color: white;
  padding: 10px 0;

  .container {
  }
`

const RegulationFooter = () => {
  return (
    <Wrapper>
      <div className="container">
        <h4 className="title">Política de calidad</h4>

        <p className="description small-font">
          En AFISERVICIOS mantenemos un fuerte compromiso con la mejora continua
          de los procesos de servicio para atender las necesidades de asesoría
          para el análisis y evaluación de la viabilidad financiera, técnica y
          legal de sus proyectos de inversión, su salud financiera y la gestión
          de créditos con instituciones bancarias, asegurando una adecuada
          gestión de riesgos y una actitud de servicio fuertemente orientada a
          exceder las expectativas y generar confianza con clientes y partes
          interesadas de la organización.
        </p>
      </div>
    </Wrapper>
  )
}

export default RegulationFooter
