import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";

const SmallCustomButtonWrapper = styled.div`
  text-align: ${props => props.align};

    .small-custom-color {
        color: var(${props => props.indicatorColor}) !important;
    }
    
    .small-primary-button {
        color: white !important;
        background-color: var(--primary-third-color) !important;
        padding: 3px 20px !important;
        border-radius: 30px !important;
        font-family: var(--font-bold) !important;
        font-size: 0.8em !important;
    }

    .small-secondary-button {
        color: var(--primary-color) !important;
        background-color: white !important;
        padding: 3px 20px !important;
        border-radius: 30px !important;
        font-family: var(--font-bold) !important;
        font-size: 0.8em !important;
    }
  
    .small-primary-button:hover {
      background-color: var(--four-color) !important;
      color: var(--primary-color) !important
    }

    .small-secondary-button:hover {
        background-color: var(--secondary-color) !important;
    }
    
   /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    .small-primary-button {
        padding: 3px 15px !important;
        font-size: 0.8em !important;
    }
    
    .small-secondary-button {
        padding: 3px 15px !important;
        font-size: 0.8em !important;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .small-primary-button {
        font-size: 1em !important;
    }
    
    .small-secondary-button {
        font-size: 1em !important;
    }
  }
`;

const SmallCustomButton = ({ label, onClick, isLoading, view, indicatorColor, containerStyle, fullWidth, align }) => (
    <SmallCustomButtonWrapper style={containerStyle} indicatorColor={indicatorColor} align={align}>
        <Button variant="contained"
                fullWidth={fullWidth}
                className={view}
                onClick={onClick}
                disabled={isLoading}>
            {
                isLoading &&
                <CircularProgress size={24}
                                  className="custom-color" />
            }
            {
                !isLoading && label
            }
        </Button>
    </SmallCustomButtonWrapper>
);

/**
 * Property types of CustomButton
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
SmallCustomButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    view: PropTypes.oneOf([
      'small-primary-button',
      'small-secondary-button'
    ]),
    indicatorColor: PropTypes.string,
    containerStyle: PropTypes.object,
    fullWidth: PropTypes.bool,
  align: PropTypes.oneOf([
    'center',
    'left',
    'right'
  ])
};

/**
 * Default property values for CustomButton
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
SmallCustomButton.defaultProps = {
    isLoading: false,
    view: 'small-primary-button',
    indicatorColor: '--four-color',
    containerStyle: {},
    fullWidth: true,
  align: 'left'
};

export default SmallCustomButton;
