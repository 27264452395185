import React from "react";
import styled from "styled-components";
import Resources from '../../resources/afiservicios-resources.json';
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaMap, FaPhone, FaTwitter } from "react-icons/fa"
import { useSiteContent } from "../hooks/use-site-content"

const FooterWrapper = styled.div`
  width: 100vw;
  height: auto;
  background-color: var(${Resources.menu_mobile.footer.bg_color});
`;

const FooterContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  padding: 30px 0;
    
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`;

const FooterRightContainer = styled.div`
  display: grid;
  justify-content: flex-end;
  align-items: center;
    
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    justify-content: center;
    grid-row: 2;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    justify-content: center;
    grid-row: 2;
  }
`;

const FooterCenterContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 3;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 3;
  }
`;

const FooterImageContainer = styled.div`
  max-width: 100%;
  max-height: auto;
  
  .img-wrapper {
    max-width: 40%;
    max-height: 200px;
  }
    
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    .img-wrapper {
      max-width: 100%;
      max-height: 150px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .img-wrapper {
      max-width: 100%;
    }
  }
`;

const FooterRightsText = styled.label`
  font-family: var(${Resources.footer.font});
  font-size: ${Resources.footer.size};
  color: var(${Resources.footer.color});
  margin: 0;
  text-align: center;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.footer.size_med};
    margin-top: 15px;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.footer.size_med};
    margin-top: 30px;
  }
`;

const FooterIconContainer = styled.div`
  
`;

const MenuMobileIconLegend = styled.div`
  display: grid;
  grid-template-columns: 2fr 8fr;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;
  
  .footer-icon {
    font-size: ${Resources.menu_mobile.footer.icon_size};
    color: var(${Resources.menu_mobile.footer.color});
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-icon {
      font-size: ${Resources.menu_mobile.footer.icon_size_med};
      color: var(${Resources.menu_mobile.footer.color});
    }
  }
`;

const MenuMobileFooterText = styled.p`
  font-family: var(${Resources.menu_mobile.footer.font});
  font-size: ${Resources.menu_mobile.footer.size};
  color: var(${Resources.menu_mobile.footer.color});
  margin: 0;
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.menu_mobile.footer.size_med};
  }
`;

const PrimaryNavbarIconsList = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  
  .icon-primary {
    font-size: ${Resources.navbar.primary.icon.size} !important;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 30px;
  
    .icon-primary {
      font-size: ${Resources.menu_mobile.footer.icon_size_med} !important;
    }
  }
`;

const PrimaryNavbarIcon = styled.a`
  color: var(${Resources.navbar.secondary.icon.color});
  font-family: var(${Resources.navbar.primary.icon.font});
  font-size: ${Resources.navbar.primary.icon.size};
  text-decoration: none;
  margin-right: 22px;
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-right: 44px;
  }
`;

const FooterPrivacyPolicyText = styled(Link)`
  font-family: var(${Resources.menu_mobile.footer.font});
  font-size: ${Resources.menu_mobile.footer.size};
  color: var(${Resources.menu_mobile.footer.color});
  margin: 0;
  text-align: center;
  text-decoration: none;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.menu_mobile.footer.size_med};
    text-align: center;
  }
`;

const Footer = () => {
    const siteContent = useSiteContent();
    return (
      <StaticQuery
        query={graphql`
      query {
        img: file(relativePath: { eq: "main-logo.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
        render={data => (
          <FooterWrapper>
              <div className="container" style={{ marginBottom: 0, marginTop: 0 }}>
                  <FooterContentWrapper>
                      <FooterImageContainer>
                          <Img
                            loading={"eager"}
                            fluid={data.img.childImageSharp.fluid}
                               className="img-wrapper"
                               imgStyle={{ objectFit: "contain", width: '100%', height: '100%' }}
                          />
                      </FooterImageContainer>
                      <FooterCenterContainer>
                          <PrimaryNavbarIconsList>
                              <PrimaryNavbarIcon href={siteContent.navbar.social_links.fb}
                                                 target="_blank">
                                  <FaFacebookF className="icon-primary" />
                              </PrimaryNavbarIcon>
                              <PrimaryNavbarIcon href={siteContent.navbar.social_links.twitter}
                                                 target="_blank">
                                  <FaTwitter className="icon-primary" />
                              </PrimaryNavbarIcon>
                              <PrimaryNavbarIcon href={siteContent.navbar.social_links.linkedin}
                                                 target="_blank">
                                  <FaLinkedinIn className="icon-primary" />
                              </PrimaryNavbarIcon>
                              <PrimaryNavbarIcon href={siteContent.navbar.social_links.instagram}
                                                 style={{ marginRight: 0 }}
                                                 target="_blank">
                                  <FaInstagram className="icon-primary" />
                              </PrimaryNavbarIcon>
                          </PrimaryNavbarIconsList>
                          <FooterPrivacyPolicyText to="/aviso-de-privacidad/">
                              {Resources.privacy_policy.title.label}
                          </FooterPrivacyPolicyText>
                          <FooterRightsText>
                              {Resources.footer.rights}
                          </FooterRightsText>
                      </FooterCenterContainer>
                      <FooterRightContainer>
                          <FooterIconContainer>
                              <MenuMobileIconLegend>
                                  <FaMap className="footer-icon" />
                                  <MenuMobileFooterText onClick={() => openMap(siteContent.footer.map_url)} style={{ cursor: 'pointer' }}>
                                      {
                                          siteContent.footer.address.map((line,key) => (<span key={key}>{line} <br/></span>))
                                      }
                                  </MenuMobileFooterText>
                              </MenuMobileIconLegend>
                          </FooterIconContainer>
                          <FooterIconContainer>
                              <MenuMobileIconLegend>
                                  <FaPhone className="footer-icon" />
                                  <MenuMobileFooterText>
                                      {
                                          siteContent.footer.lista_telefonos.map((phone, key) => (<span key={key}>{phone} <br/></span>))
                                      }
                                  </MenuMobileFooterText>
                              </MenuMobileIconLegend>
                          </FooterIconContainer>
                      </FooterRightContainer>
                  </FooterContentWrapper>
              </div>
          </FooterWrapper>
        )} />
    );
}

const openMap = (url) => {
    window.open(url, '_blank');
};

export default Footer;
